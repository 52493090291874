/**
 * Custom scripts
 * ==============
 */
// javascript is enabled

import { Tab, Collapse, ScrollSpy } from 'bootstrap';
import { Howl, Howler } from 'howler';
import { tns } from '../../node_modules/tiny-slider/src/tiny-slider';
import animation from './gsap.js';
import smoothscroll from 'smoothscroll-polyfill';
import { smoothScroll } from './smoothscroll';
import LazyLoad from 'vanilla-lazyload';
import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";

const body = document.getElementsByTagName('body')[0];
body.classList.remove('no-js');

let showMenu = (menuAction) => {
	const menu = document.getElementById('fullscreenMenu');

	if (menuAction == 'open') {
		body.classList.add('openedMenu');
		menu.setAttribute('aria-hidden', 'false');
	}
	if (menuAction == 'close') {
		body.classList.remove('openedMenu');
		menu.setAttribute('aria-hidden', 'true');
	}
};

window.addEventListener('load', () => {
  
	animation();
	let lazyLoadInstance = new LazyLoad();
});

document.addEventListener('DOMContentLoaded', () => {
	const pageForm = document.querySelector('body.form');
	if (pageForm) {
		const messageHide = document.querySelector('#main');
		const message = document.querySelector('#my-form-status');
		document.addEventListener(
			'wpcf7submit',
			function (event) {
        
        if(event.detail.status != 'validation_failed'){
          document.querySelector('form').style.display = 'none';
          messageHide.style.display = 'none';
          message.style.display = 'block';
          dataLayer.push({
            'event': 'formSubmission',
          });
        }
				
			},
			false
		);
	}

	const menu = document.getElementById('menuBtn');
	const menuClose = document.getElementsByClassName('closeBtn')[0];

	const pageHome = document.getElementById('pageHome');
	if (pageHome) {
		/* accordion */

		let collapsibles = document.querySelectorAll('#accordionExample .accordion-collapse');
		collapsibles.forEach((element) => {
			element.addEventListener('show.bs.collapse', function () {
				this.parentElement.classList.add('opened');
			});
			element.addEventListener('hide.bs.collapse', function () {
				this.parentElement.classList.remove('opened');
			});
		});

		const bodyEL = document.getElementsByTagName('body')[0];
		/* audio  */
		const sound = new Howl({
			src: ['assets/sound.mp3'],
			loop: true,
		});
		/* const audioBtn = document.getElementById('menuAudio'); */
		const audioImage = document.querySelector('section.newTop .mask');

		/* audioBtn.addEventListener('click', (e) => {
			e.preventDefault();

			if (sound.playing()) {
				sound.pause();
				bodyEL.classList.remove('playing');
			} else {
				sound.play();
				bodyEL.classList.add('playing');
			}
		}); */
		audioImage.addEventListener('click', (e) => {
			e.preventDefault();

			if (sound.playing()) {
				sound.pause();
				bodyEL.classList.remove('playing');
			} else {
				sound.play();
				bodyEL.classList.add('playing');
			}
		});
		/* slider */
		var slider = tns({
			container: '.plan-slider',
			items: 2,
			slideBy: 1,
			controls: true,
			nav: false,
			center: true,
			loop: true,
			gutter: 20,
			fixedWidth: 300,
			controlsText: ['', ''],
			lazyload: true,
			responsive: {
				600: {
					gutter: 100,
					fixedWidth: 940,
					items: 2,
				},
			},
		});
    slider.events.on('indexChanged', foo);
    function foo() {
      const currentIndex = document.querySelector('.sliderCount .current span');;
      currentIndex.innerHTML = slider.getInfo().displayIndex;
      
    }
		/* scrollspy */
		const scrollSpy = new ScrollSpy(document.body, {
			target: '#nav',
			offset: -100,
		});
		const links = document.querySelectorAll('.list-group-item');

		var firstScrollSpyEl = document.querySelector('[data-bs-spy="scroll"]');
		firstScrollSpyEl.addEventListener('activate.bs.scrollspy', function () {
			links.forEach(function (el, index) {
				el.classList.remove('active');
			});
		});
	}

	if (menu) {
		menu.addEventListener('click', (e) => {
			e.preventDefault();
			showMenu('open');
		});
	}
	if (menuClose) {
		menuClose.addEventListener('click', (e) => {
			e.preventDefault();
			showMenu('close');
		});
	}
  const virtual_tour = document.querySelector('.virtual_tour');
  if(virtual_tour){
    const overlay = virtual_tour.querySelector('.overlay');
    const button = virtual_tour.querySelector('button#start_tour');
    const buttonLeave = virtual_tour.querySelector('button#leave_tour');
    button.addEventListener('click', (e) => {
      overlay.classList.add('hidden');
      buttonLeave.classList.remove('hidden');
    })
    buttonLeave.addEventListener('click', (e) => {
      overlay.classList.remove('hidden');
      buttonLeave.classList.add('hidden');
    })
  }

  /**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
CookieConsent.run({

  root: 'body',
  autoShow: true,
  disablePageInteraction: false,
  hideFromBots: true,
  mode: 'opt-in',
  revision: 0,

  cookie: {
    name: 'cc_cookie',
    domain: location.hostname,
    path: '/',
    sameSite: "Lax",
    expiresAfterDays: 365,
  },

  // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
  guiOptions: {
      consentModal: {
          layout: 'cloud inline',
          position: 'bottom center',
          equalWeightButtons: true,
          flipButtons: false
      },
      preferencesModal: {
          layout: 'box',
          equalWeightButtons: true,
          flipButtons: true
      }
  },

  /* onFirstConsent: ({cookie}) => {
      console.log('First consent!');
      var ad_storage, analytics_storage;
      var cookie_categories = cookie.categories;
      
      cookie_categories.includes('ads') ? ad_storage = "granted" : ad_storage = "denied";
      cookie_categories.includes('analytics') ? analytics_storage = "granted" : analytics_storage = "denied";

      gtag('consent', 'update', {
        'security_storage': 'granted',
        'ad_storage': ad_storage,
        'ad_user_data': ad_storage,
        'ad_personalization': ad_storage,
        'analytics_storage': analytics_storage,
        'personalization_storage': ad_storage,
        'functionality_storage': "granted",
        
      });
      dataLayer.push({
        'event': 'cookie_consent_update',
      });

  }, */

  onChange: ({changedCategories, changedServices, cookie}) => {

    

    var ad_storage, analytics_storage;
    var cookie_categories = cookie.categories;
    
    cookie_categories.includes('ads') ? ad_storage = "granted" : ad_storage = "denied";
    cookie_categories.includes('analytics') ? analytics_storage = "granted" : analytics_storage = "denied";
    
    
    gtag('consent', 'update', {
      'security_storage': 'granted',
      'ad_storage': ad_storage,
      'ad_user_data': ad_storage,
      'ad_personalization': ad_storage,
      'analytics_storage': analytics_storage,
      'personalization_storage': ad_storage,
      'functionality_storage': "granted",
      
    });
    dataLayer.push({
      'event': 'cookie_consent_update',
     });
  },

  onConsent: ({cookie}) =>{

    var ad_storage, analytics_storage;
    var cookie_categories = cookie.categories;
    
    cookie_categories.includes('ads') ? ad_storage = "granted" : ad_storage = "denied";
    cookie_categories.includes('analytics') ? analytics_storage = "granted" : analytics_storage = "denied";

    gtag('consent', 'update', {
      'security_storage': 'granted',
      'ad_storage': ad_storage,
      'ad_user_data': ad_storage,
      'ad_personalization': ad_storage,
      'analytics_storage': analytics_storage,
      'personalization_storage': ad_storage,
      'functionality_storage': "granted",
      
    });
    dataLayer.push({
      'event': 'cookie_consent_update',
     });
  },

  categories: {
      necessary: {
          enabled: true,  // this category is enabled by default
          readOnly: true  // this category cannot be disabled
      },
      analytics: {
          autoClear: {
              cookies: [
                  {
                      name: /^_ga/,   // regex: match all cookies starting with '_ga'
                  },
                  {
                      name: '_gid',   // string: exact cookie name
                  }
              ]
          },
      },
      ads: {}
  },

  language: {
      default: 'en',
      translations: {
          en: {
              consentModal: {
                  title: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path d="M21.7509 9.77215C20.7538 9.77215 19.7976 9.37605 19.0925 8.67101C18.3875 7.96597 17.9914 7.00974 17.9914 6.01266C17.9914 5.95557 17.9914 5.89988 17.9914 5.84279C17.3321 6.04817 16.629 6.06801 15.9592 5.9001C15.2893 5.7322 14.6787 5.38304 14.1942 4.89095C13.7098 4.39886 13.3702 3.78283 13.2128 3.11047C13.0553 2.4381 13.0861 1.73539 13.3018 1.07938C12.8697 1.02755 12.4351 1.00104 11.9999 1C9.72263 1.00002 7.50148 1.70682 5.64299 3.0229C3.7845 4.33897 2.38028 6.19944 1.62412 8.34752C0.867958 10.4956 0.797097 12.8254 1.42138 15.0155C2.04566 17.2055 3.3343 19.1478 5.10941 20.5743C6.88453 22.0009 9.05864 22.8413 11.3317 22.9797C13.6048 23.118 15.8648 22.5475 17.7998 21.3468C19.7348 20.1461 21.2495 18.3744 22.1348 16.2762C23.02 14.178 23.2323 11.8568 22.7423 9.63291C22.4196 9.72379 22.0861 9.77063 21.7509 9.77215V9.77215Z" stroke="#0F413C" stroke-width="1.8" stroke-miterlimit="10" stroke-linejoin="round"/><path d="M9 16C9.55228 16 10 15.5523 10 15C10 14.4477 9.55228 14 9 14C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16Z" fill="#0F413C"/><path d="M10 10C10.5523 10 11 9.55228 11 9C11 8.44772 10.5523 8 10 8C9.44772 8 9 8.44772 9 9C9 9.55228 9.44772 10 10 10Z" fill="#0F413C"/><path d="M15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12C14.4477 12 14 12.4477 14 13C14 13.5523 14.4477 14 15 14Z" fill="#0F413C"/></svg><span>Pro zlepšení webu používáme Cookies.</span>',
                  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                  acceptAllBtn: 'Povolit vše',
                  acceptNecessaryBtn: 'Odmítnout vše',
                  showPreferencesBtn: 'Nastavení',
                  // closeIconLabel: 'Reject all and close modal',
                  footer: `
                      <a href="#path-to-impressum.html" target="_blank">Impressum</a>
                      <a href="#path-to-privacy-policy.html" target="_blank">Privacy Policy</a>
                  `,
              },
              preferencesModal: {
                  title: 'Napekli jsme koláčky',
                  acceptAllBtn: 'Povolit vše',
                  acceptNecessaryBtn: 'Odmítnout vše',
                  savePreferencesBtn: 'Uložit nastavení',
                  closeIconLabel: 'Zavřit',
                  serviceCounterLabel: 'Služba|Služby',
                  sections: [
                      {
                          title: 'Využití cookies 📢',
                          description: `Soubory cookies jsou malé textové soubory, které se ukládají do vašeho zařízení při návštěvě webových stránek. Soubory cookies používáme pro vylepšení vašeho online zážitku na webové stránce.`,
                      },
                      {
                          title: 'Bezpodmínečně nutné soubory cookies',
                          description: 'Tyto soubory cookies jsou nezbytné k tomu, abychom vám mohli poskytovat služby dostupné prostřednictvím našeho webu a abychom vám umožnili používat určité funkce našeho webu. Bez těchto cookies vám nemůžeme na naší webové stránce poskytovat určité služby.',

                          //this field will generate a toggle linked to the 'necessary' category
                          linkedCategory: 'necessary'
                      },
                      {
                          title: 'Analytické soubory cookies',
                          description: 'Tyto soubory cookies se používají ke shromažďování informací pro analýzu provozu na našich webových stránkách. Tyto soubory cookies mohou například sledovat věci jako o které stránky se návštěvníci zajímají a kolik času tím tráví, což nám pomáhá pochopit, jak pro vás můžeme vylepšit náš web. Tímto se neidentifikují žádné osoby.',
                          linkedCategory: 'analytics',
                          
                      },
                      {
                          title: 'Cookies pro cílení a reklamu',
                          description: 'Tyto soubory cookie se používají k zobrazování reklamy, která vás pravděpodobně bude zajímat na základě vašich zvyků při procházení. Tyto soubory cookie, jsou požadovány námi/nebo poskytovateli reklam, mohou kombinovat informace shromážděné z našich webových stránek s dalšími informacemi, které nezávisle shromáždily z jiných webových stránek, týkající se činností vašeho internetového prohlížeče v rámci jejich reklamní sítě webových stránek. Pokud se rozhodnete tyto soubory cookies pro cílení nebo reklamu odstranit nebo deaktivovat, budou se vám reklamy stále zobrazovat, ale nemusí pro vás být nadále personalizované a relevantní.',
                          linkedCategory: 'ads',
                      }
                      
                  ]
              }
          }
      }
  }
});
});

function preventScroll(e){
  e.preventDefault();
  e.stopPropagation();

  return false;
}