import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import marquee from 'vanilla-marquee';

import SplitType from 'split-type';
import { fill, transform } from 'lodash';

gsap.registerPlugin(ScrollTrigger);

function wrapLine(lines) {
	lines.forEach((line, index) => {
		let origLine = line.innerHTML;
		let wrappedLine = '<div class="lineInner" data-order="' + index + '">' + origLine + '</div>';
		line.innerHTML = wrappedLine;
	});
}
function removeOverflow(target) {
	target.parentElement.classList.add('overflowNormal');
}
function getAngles(element) {
	let angleArr = [];
	element.forEach((element) => {
		let style = window.getComputedStyle(element);
		var tr =
			style.getPropertyValue('-webkit-transform') ||
			style.getPropertyValue('-moz-transform') ||
			style.getPropertyValue('-ms-transform') ||
			style.getPropertyValue('-o-transform') ||
			style.getPropertyValue('transform') ||
			"Either no transform set, or browser doesn't do getComputedStyle";

		var values = tr.split('(')[1],
			values = values.split(')')[0],
			values = values.split(',');

		var b = values[1];

		var angle = Math.round(Math.asin(b) * (180 / Math.PI));
		angleArr.push(angle);
	});
	return angleArr;
}

export default function animation() {
	gsap.registerPlugin(CustomEase);
	CustomEase.create('slideEase', 'M0,0,C0.92,0.012,0.108,1,1,1');

	const logo = document.querySelector('header .logo');
	const logoSplit = new SplitType(logo, { types: 'chars' });

	const pageHome = document.getElementById('pageHome');
	if (pageHome) {
		/* SEKCE obrazky v textu */
		const textWithImages = new SplitType('#scrollTarget', { types: 'lines, words' });
		wrapLine(textWithImages.lines);

		const linesWithImages = document.querySelectorAll('#scrollTarget .lineInner');
		const smallImages = document.querySelectorAll('section.newTop .text .img');
		const smallImages1 = document.querySelector('section.newTop .text .img[data-order="0"]');
		const smallImages2 = document.querySelector('section.newTop .text .img[data-order="1"]');
		const smallImages3 = document.querySelector('section.newTop .text .img[data-order="2"]');
		const button = document.querySelectorAll('section.newTop .text .btn-primary.light');

		const mql = window.matchMedia('(min-width: 768px)');
		if (mql.matches) {
			const imageAngles = getAngles(smallImages);
			let textImgTl = gsap.timeline({
				scrollTrigger: {
					trigger: '#scrollTarget',
					scrub: false,
				},
			});
			textImgTl
				.from(linesWithImages, { y: '100%', duration: 0.8, stagger: 0.1, delay: 0.8 })
				.call(function () {
					linesWithImages.forEach((element) => {
						removeOverflow(element);
					});
				})
				.from(smallImages1, { opacity: 0, y: 100, rotate: imageAngles[0] - 10 })
				.from(smallImages2, { opacity: 0, y: 100, rotate: imageAngles[1] - 10 })
				.from(smallImages3, { opacity: 0, y: 100, rotate: imageAngles[2] - 10 })
				.from(button, { opacity: 0, stagger: 0.2 });
		} else {
			let textImgTl = gsap.timeline({
				scrollTrigger: {
					trigger: '#scrollTarget',
					scrub: false,
				},
			});
			textImgTl
				.from(linesWithImages, { y: '100%', duration: 0.8, stagger: 0.1, delay: 0.8 })
				.call(function () {
					linesWithImages.forEach((element) => {
						removeOverflow(element);
					});
				})
				.from(button, { opacity: 0 });
		}

		/* pin animace obrazku */
		let mobilePin;
		let maskSize, arrowSpeed, imageScale;
		let maskSizeOrig, borderRadiusOrig;
		const mediaQuery = window.matchMedia('(min-width: 768px)');

		if (mediaQuery.matches) {
			arrowSpeed = 0.02;
			maskSize = '75vw';
			imageScale = 0.8;
			mobilePin = true;
			maskSizeOrig = '45vw';
			borderRadiusOrig = '442px';
		} else {
			arrowSpeed = 0.08;
			maskSize = '80vw';
			imageScale = 0.9;
			mobilePin = false;
			maskSizeOrig = '70vw';
			borderRadiusOrig = '142px';
		}
		if (mobilePin) {
			const pinSection = document.querySelector('section.materials .topRow');
			const right = document.querySelector('section.materials .topRow .rightPart .img');
			const left = document.querySelector('section.materials .topRow .leftPart .img');
			const leftText = document.querySelector('section.materials .topRow .leftPart .par-b');
			const rightText = document.querySelector('section.materials .topRow .rightPart .par-b');
			let pinAmount = pinSection.clientHeight;
			const pinTl = new gsap.timeline({
				scrollTrigger: {
					trigger: pinSection,
					scrub: 0.3,
					pin: true,

					start: '-10%',
				},
			});
			pinTl
				.fromTo(
					left,
					{
						scale: 2.28,
					},
					{ scale: 1 }
				)
				.fromTo(
					right,
					{
						scale: 0.5,
					},
					{ scale: 1 },
					0
				)
				.fromTo(
					leftText,
					{
						opacity: 0,
					},
					{ opacity: 1 },
					0.2
				);
		}

		/* pred patickou */
		const aboutSection = document.querySelector('section.about');
		const footerImage = document.querySelector('section.about .rightPart .img ');
		const footerImageInner = document.querySelector('section.about .rightPart .img img');
		const aboutTl = new gsap.timeline({
			scrollTrigger: {
				scrub: 0.5,
				trigger: aboutSection,

				start: '${pinAmount}px 90%',
				end: '100% bottom',
			},
		});

		aboutTl
			.fromTo(
				footerImage,
				{
					webkitClipPath: 'inset(8% 0% 0% 8%)',
				},
				{
					webkitClipPath: 'inset(0% 0% 0% 0%)',
				}
			)
			.fromTo(
				footerImageInner,
				{
					scale: 1.05,
				},
				{
					scale: 1,
				},
				0
			);

		/* full image anim scroll */
		const fullImage = document.querySelector('section.usporadani .fullImg .img img');
		gsap.to(fullImage, {
			scrollTrigger: {
				scrub: true,
				trigger: fullImage,

				start: '${pinAmount}px 30%',
			},
			scale: 1.08,
		});

		/* barva hlavicky */
		const lightSections = gsap.utils.toArray('section.light');
		const bodyEL = document.getElementsByTagName('body')[0];
		const footerEl = document.getElementsByTagName('footer')[0];
		lightSections.forEach((section) => {
			gsap.to(section, {
				scrollTrigger: {
					trigger: section,

					start: '${pinAmount}px top',
					onEnter: () => {
						bodyEL.classList.add('darkenHead');
					},
					onEnterBack: () => {
						bodyEL.classList.add('darkenHead');
					},
					onLeave: () => {
						bodyEL.classList.remove('darkenHead');
					},
					onLeaveBack: () => {
						bodyEL.classList.remove('darkenHead');
					},
				},
			});
			/* barva nav */
			gsap.to(section, {
				scrollTrigger: {
					trigger: section,
					start: 'top bottom',
					end: 'bottom bottom',
					onEnter: () => {
						bodyEL.classList.add('darkenNav');
					},
					onEnterBack: () => {
						bodyEL.classList.add('darkenNav');
					},
					onLeave: () => {
						bodyEL.classList.remove('darkenNav');
					},
					onLeaveBack: () => {
						bodyEL.classList.remove('darkenNav');
					},
				},
			});
			/* schovani nav v paticce */
			gsap.to(footerEl, {
				scrollTrigger: {
					trigger: footerEl,
					start: 'top bottom',
					end: 'bottom bottom',
					onEnter: () => {
						bodyEL.classList.add('hideNav');
					},

					onLeaveBack: () => {
						bodyEL.classList.remove('hideNav');
					},
				},
			});
		});
		/* vyska sekci */

		const sectionOne = document.getElementById('section-1');
		const sectionTwo = document.getElementById('section-2');
		const sectionThree = document.getElementById('section-3');

		const height1 = sectionOne.clientHeight;
		const height2 = sectionTwo.clientHeight;
		const height3 = sectionThree.clientHeight;
		let sumHeight = height1 + height2 + height3;

		let perc1 = (height1 / sumHeight) * 100;
		let perc2 = (height2 / sumHeight) * 100;
		let perc3 = (height3 / sumHeight) * 100;

		const meterInnerOne = document.getElementById('innerOne');
		const meterInnerTwo = document.getElementById('innerTwo');
		const meterInnerThree = document.getElementById('innerThree');

		meterInnerTwo.style.top = perc1 + '%';
		meterInnerThree.style.top = perc1 + perc2 + '%';

		gsap.to(meterInnerOne, {
			scrollTrigger: {
				trigger: sectionOne,
				scrub: true,
				end: 'bottom bottom',
			},
			height: perc1 + '%',
			ease: 'none',
		});
		gsap.to(meterInnerTwo, {
			scrollTrigger: {
				trigger: sectionTwo,
				scrub: true,
				end: 'bottom bottom',
			},
			height: perc2 + '%',
			ease: 'none',
		});
		gsap.to(meterInnerThree, {
			scrollTrigger: {
				trigger: sectionThree,
				scrub: true,
				end: 'bottom bottom',
			},
			height: perc3 + '%',
			ease: 'none',
		});

		/* marquue na scroll */
		const marqueeScroll = document.getElementById('marquee');
		gsap.to(marqueeScroll, {
			scrollTrigger: {
				trigger: marqueeScroll,
				start: '-60% 100%',
				end: 'bottom top',
				scrub: true,
			},
			left: '-50vw',
			ease: 'none',
		});

		const marqueeSplit = new SplitType(marqueeScroll, { types: 'chars' });
		let marqueeChar = document.querySelectorAll('#marquee .char');

		let marqueeStar = document.querySelectorAll('#marquee svg');

		marqueeChar.forEach((element) => {
			element.addEventListener('mouseenter', (e) => {
				gsap.to(element, { color: 'rgba(15, 60, 55, 1)', duration: 0.4, overwrite: true });
			});
			element.addEventListener('mouseleave', (e) => {
				gsap.to(element, { color: 'rgba(15, 60, 55, 0)', duration: 3, delay: 1, overwrite: true });
			});
		});

		marqueeStar.forEach((element) => {
			element.addEventListener('mouseenter', (e) => {
				gsap.to(element.querySelector('.fill'), { fill: 'rgba(15, 60, 55, 1)', duration: 0.4, overwrite: true });
			});
			element.addEventListener('mouseleave', (e) => {
				gsap.to(element.querySelector('.fill'), { fill: 'rgba(15, 60, 55, 0)', duration: 3, delay: 1, overwrite: true });
			});
		});

		const cursorSvg = document.querySelector('#cursor .wave');

		const svg = new marquee(cursorSvg, {
			direction: 'right',
			duplicated: true,
			startVisible: true,
			speed: 10,
			recalcResize: false,
			gap: -0.1,
		});

		/* cursor top*/
		const image = document.querySelector('section.newTop  .mask');
		if (image) {
			bodyEL.addEventListener('mousemove', (e) => {
				let coordX = e.clientX;
				let coordY = e.clientY;

				gsap.to('#cursor', {
					top: coordY - 60,
					left: coordX - 60,
					duration: 0.15,
				});
			});
			image.addEventListener('mouseenter', (e) => {
				gsap.to('#cursor', {
					opacity: 1,
					duration: 0.2,
					scale: 1.2,
				});
			});
			image.addEventListener('mouseleave', (e) => {
				gsap.to('#cursor', {
					duration: 0.2,
					scale: 0,
				});
			});
		}

		/* claim lines animation */
		const claimSplit = new SplitType('#splitClaim', { types: 'lines' });
		wrapLine(claimSplit.lines);
		const claimLines = document.querySelectorAll('#splitClaim .lineInner');
		const claimSymbol = document.getElementById('quote');
		const claimAuthor = document.querySelector('section.usporadani .author');

		const claimTl = gsap.timeline({
			scrollTrigger: {
				trigger: '#splitClaim',
			},
		});
		claimTl
			.from(claimLines, { y: '100%', duration: 0.8, stagger: 0.1, delay: 0.8 })
			.call(function () {
				claimLines.forEach((element) => {
					removeOverflow(element);
				});
			})
			.from(claimSymbol, { opacity: 0 }, 0.9)
			.from(claimAuthor, { opacity: 0 });

		/* uvodni animace */
		const musicBtn = document.querySelector('header .musicPart a');
		const musicBtnLine = document.querySelector('header .musicPart a span:not(.m_hide)');
		const logo = document.querySelector('header a.logo');
		const zajemBtn = document.querySelector('header .menuPart a');
		const scrollBtn = document.querySelector('.newTop .scrollWrap > .smooth-scroll');
		const scrollMenu = document.querySelector('.newTop  .scrollWrap nav');

		const newTop = document.querySelector('section.newTop.v1 ');
		const maskImage = document.querySelector('section.newTop.v1 img');
		const imageWrap = document.querySelector('section.newTop.v1 .imageWrap');
		const mask = document.querySelector('section.newTop.v1 .mask');

		/* Creates a new SplitType instance */
		const text = new SplitType('#test', { types: 'lines, words' });

		/* Zabalit do divu */
		wrapLine(text.lines);
		const foo = document.querySelectorAll('.headingTest .lineInner .word');
		const mainHeading = document.querySelectorAll('.headingTest');
		const headerFlag = 1;
		const maskFlag = 1.4;
		let initTimeline = gsap.timeline({
			paused: false,
			onComplete: function () {
				removeOverflow(mainHeading[0]);
			},
		});

		initTimeline
			.from(foo, { duration: 1, y: '100%', opacity: 0, rotate: 15, stagger: 0.12, delay: 0.5 })
			.fromTo(maskImage, { scale: imageScale + 0.2 }, { scale: imageScale, duration: 1.5 }, maskFlag)
			.from(imageWrap, { opacity: 0, duration: 1 }, maskFlag)
			.from(mask, { width: '30vw', height: '40%', ease: 'slideEase', duration: 1.5 }, maskFlag)
			.from(logo, { opacity: 0, duration: 0.8 }, headerFlag)
			.from(musicBtn, { opacity: 0, duration: 0.8 }, headerFlag)
			.from(zajemBtn, { opacity: 0, duration: 0.8 }, headerFlag)
			.from(musicBtnLine, { webkitClipPath: 'inset(0% 100% 0% 0%)', duration: 0.8 } /* , headerFlag + 0.2 */)
			.from(scrollBtn, { opacity: 0, duration: 1 }, maskFlag + 0.9)
			.from(scrollMenu, { opacity: 0, duration: 1 }, maskFlag + 0.9);

		/* animace top na scroll */
		const scrollArrow = document.querySelector('.scrollWrap .smooth-scroll');

		let maskTl = new gsap.timeline({
			scrollTrigger: {
				start: '1%',
				end: '50%',
				trigger: newTop,
				scrub: 0.5,
			},
		});
		maskTl
			.fromTo(
				mask,
				{ borderRadius: borderRadiusOrig, width: maskSizeOrig },
				{
					borderRadius: 40,
					width: maskSize,
					ease: 'power1.out',
				}
			)
			.fromTo(
				maskImage,
				{
					scale: imageScale,
					z: 0.01,
				},
				{
					scale: 1,
				},
				0
			)

			.to(
				scrollArrow,
				{
					autoAlpha: 0,
					duration: arrowSpeed,
				},
				0
			);

		/* animace nadpisu v sekcich */
		const sectionHeadings = document.querySelectorAll('section .sectionHeading');
		sectionHeadings.forEach((heading) => {
			const split = new SplitType(heading, { types: 'lines, words,chars' });
			wrapLine(split.lines);
		});
		const sectionHeadingsLines = document.querySelectorAll('section .sectionHeading .lineInner');
		sectionHeadings.forEach((section) => {
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: section,
					scrub: false,
					markers: false,
				},
				onComplete: function () {
					removeOverflow(section);
				},
			});
			tl.from(section.querySelectorAll('.lineInner .char'), {
				duration: 0.6,
				y: '100%',
				opacity: 0,
				rotate: 15,
				stagger: 0.02,
				delay: 0.5,
			}).from(
				section.parentElement.querySelector(' small'),
				{
					opacity: 0,
					duration: 1,
				},
				0.5
			);
		});
	}
}
